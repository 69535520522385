import React, { Component } from "react";
import { Layout } from "antd";

import logo from "assets/icons/logo_light.svg";

const { Footer } = Layout;

class AppFooter extends Component {
  render() {
    return (
      <Footer>
        <img src={logo} alt="logo" className="logo" />

        <p style={{fontSize: "14px"}}>
          Copyright © {new Date().getFullYear()} Giuseppe Vecchio. All rights
          reserved.
        </p>
      </Footer>
    );
  }
}

export default AppFooter;

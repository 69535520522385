import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Col } from "antd";
import {
  LinkOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  //FacebookOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

const ConditionalButton = ({
  url,
  icon = <LinkOutlined />,
  isLink = false,
}) => {
  if (!isLink) return url ? <a href={url}>{icon}</a> : null;
  else return url ? <Link to={url}>{icon} READ MORE</Link> : null;
};

const ConditionalBadge = ({ badgeText, children }) => {
  return badgeText ? (
    <Badge.Ribbon text={badgeText} color="green">
      {children}
    </Badge.Ribbon>
  ) : (
    <>{children}</>
  );
};

const ProjectCard = ({ title, description, img, link, yt, ig, ext, badge }) => {
  const actionsBlock = [];

  if (link) actionsBlock.push(<ConditionalButton url={link} isLink={true} />);
  if (yt)
    actionsBlock.push(
      <ConditionalButton url={yt} icon={<YoutubeOutlined />} />
    );
  if (ig)
    actionsBlock.push(
      <ConditionalButton url={ig} icon={<InstagramOutlined />} />
    );
  if (ext)
    actionsBlock.push(<ConditionalButton url={ext} icon={<LinkOutlined />} />);

  return (
    <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
      <ConditionalBadge badgeText={badge}>
        <Card
          hoverable
          cover={<img alt={img.alt} src={img.src} />}
          actions={actionsBlock}
        >
          <Meta
            title={<h5 style={{ whiteSpace: "initial" }}>{title}</h5>}
            description={description}
          />
        </Card>
      </ConditionalBadge>
    </Col>
  );
};

// const BigProjectCard = ({ title, description, icon, link, action_alt, badge }) => {
//   const actionsBlock = [];
//   if (link) {
//     actionsBlock.push(<ConditionalButton url={link} icon={<LinkOutlined />} />);
//   } else if (action_alt) {
//     actionsBlock.push(action_alt);
//   }

//   const logo = (
//     <img
//       alt={title}
//       src={icon}
//       style={{ width: "100%", padding: "1px" }}
//     />
//   )

//   const visibleBadge = badge ? "visible" : "hidden";
//   return (
//     <>
//       <Col md={12} lg={8} style={{ width: "100%" }}>
//         <Badge.Ribbon text={badge} color="red" style={{ visibility: visibleBadge }}>
//           <Card
//             hoverable
//             cover={logo}
//             actions={actionsBlock}
//           >
//             <Meta title={title} description={description} />
//           </Card>
//         </Badge.Ribbon>
//       </Col>
//     </>
//   );
// };

export default ProjectCard;

import React from "react";
// import { Link } from "react-router-dom";
import { Card, Col } from "antd";

import { DeviceSizeView } from "components/common";

const { Meta } = Card;


const IconCard = ({ title, description, icon }) => {
    const img = icon ? (<img
      alt={title}
      src={icon}
      style={{ width: 60, margin: "auto" }}
    />) : null;
    return (
      <>
        <Col md={12} lg={8} style={{ width: "100%" }}>
          <DeviceSizeView screen_size="md" mode="gt">
            <Card
              hoverable
              style={{ minHeight: "100%", minWidth: "100%", paddingTop: 24 }}
              cover={img}
            >
              <Meta title={title} description={description} />
            </Card>
          </DeviceSizeView>
  
          <DeviceSizeView screen_size="md" mode="lt">
            <Card style={{ minWidth: "100%", textAlign: "left" }}>
              <Meta
                avatar={img}
                title={title}
                description={description}
              />
            </Card>
          </DeviceSizeView>
        </Col>
      </>
    );
  };

export default IconCard;
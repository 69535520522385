import React from "react";

const sizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

const DeviceSizeView = ({ children, screen_size="sm", mode = "gt" }) => {
  const threshold = sizes[screen_size];
  
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  if (mode === "lt") return width < threshold ? children : null;
  else return width > threshold ? children : null;
};

export default DeviceSizeView;
